// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAWirp8fuucmSA6cJOhNvGfaXY1KODMApo",
  authDomain: "ecommerceclone7.firebaseapp.com",
  projectId: "ecommerceclone7",
  storageBucket: "ecommerceclone7.appspot.com",
  messagingSenderId: "415307966912",
  appId: "1:415307966912:web:9196670ff37c72ad098025"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;