import React, { useEffect, useState } from 'react';
import HeaderBottom from '../components/HomePage/HeaderBottom';

const ContactUs = () => {
  const [footerAddress, setFooterAddress] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch(`https://ecommerce7clone-d13f0d31e43d.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, []);

  const handleMessage = (event) => {
    event.preventDefault();
    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus: 'UnRead',
    };

    fetch(`https://ecommerce7clone-d13f0d31e43d.herokuapp.com/add-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        alert('Message is Updated');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <HeaderBottom />
      <div className="contact-section-six mt-160 mb-100 lg-mt-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="form-style-three ps-0 sm-pb-80">
                <form onSubmit={handleMessage} id="contact-form">
                  <div className="messages" />
                  <div className="row controls">
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-25">
                        <label>Name*</label>
                        <input
                          type="text"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-25">
                        <label>Email*</label>
                        <input
                          type="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-25">
                        <label>Phone*</label>
                        <input
                          type="text"
                          placeholder="Your Phone"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-25">
                        <label>Subject*</label>
                        <input
                          type="text"
                          placeholder="Your Subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-35">
                        <textarea
                          placeholder="Your message*"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="theme-btn-one ripple-btn w-100" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {footerAddress.map((c, index) => (
              <div className="col-lg-5 col-md-6 ms-auto" key={index}>
                <div className="address-block-three d-flex mb-80 lg-mb-40">
                  <div className="icon">
                    <img src="images/icon/icon_17.svg" alt="" />
                  </div>
                  <div className="text">
                    <h5 className="title">Our Address</h5>
                    <p>{c.Location}</p>
                  </div>
                </div>
                <div className="address-block-three d-flex mb-80 lg-mb-40">
                  <div className="icon">
                    <img src="images/icon/icon_18.svg" alt="" />
                  </div>
                  <div className="text">
                    <h5 className="title">Contact Info</h5>
                    <p>{c.footerEmail}</p>
                  </div>
                </div>
                <div className="address-block-three d-flex">
                  <div className="icon">
                    <img src="images/icon/icon_19.svg" alt="" />
                  </div>
                  <div className="text">
                    <h5 className="title">Phone</h5>
                    <p>{c.Phone}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
